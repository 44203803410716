import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useRecoilValue } from 'recoil';
import { lighten } from 'polished';
import { trackAssistantButtonClick } from '../../models/assistant/analytics';
import { assistantSelectors } from '../../models/assistant';
import { AssistantIcon, Button, CircleTimer, Icon } from '../ui';
import { AssistantLandingItem } from './assistant-landing-item';
import useProgramTheme from '../../common/use-program-theme';
import useAssistantMessages from '../../common/use-assistant-messages';
import './assistant.scss';
import { programSelectors } from '../../models/program';
import { uiSelectors } from '../../models/ui';
import useScreenSize from '../../common/use-screen-size';
import { paths as pathsState } from '../../models/path-history/recoil-state';
import { AssistantInboxItem } from '../../models/assistant/selectors';
import { RootPatronState } from '../../common/use-patron-selector';
import Tooltip from '../tooltip/tooltip';
import useFeatureFlag from '../../common/use-feature-flag';
import { Feature } from '../../models/features/features';

interface AssistantButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isOpen?: any;
  count?: number;
  messageItem?: AssistantInboxItem;
  assistantExperimentEnabled?: boolean;
  overlayIsActive?: boolean;
  searchBarInHeaderEnabled?: boolean;
}

function AssistantButton({
  isOpen,
  count = 0,
  messageItem,
  assistantExperimentEnabled,
  overlayIsActive,
  searchBarInHeaderEnabled,
}: AssistantButtonProps) {
  const { t } = useTranslation();
  const { themeColor } = useProgramTheme();
  const paths = useRecoilValue(pathsState);
  const history = useHistory();

  const { message, readMessage, isRead, isDismissed } = useAssistantMessages();
  const showMessage = !isOpen && message && !isRead && !isDismissed;
  const [showNotification, setShowNotification] = useState(false);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const prevIsOpen = useRef<HTMLAllCollection | null>(null);

  const { isTabletOrLarger } = useScreenSize();
  const notificationCenterEnabled = useFeatureFlag(
    Feature.NOTIFICATION_CENTER_ENABLED
  );

  useEffect(() => {
    const canAutoOpenAssistant = () => {
      return (
        isTabletOrLarger &&
        assistantExperimentEnabled &&
        paths &&
        ((paths.length > 1 &&
          paths[1].match(/(feed|channels|account|discover)\/?/) &&
          !paths[1].match(/assistant/)) ||
          paths.length === 1) &&
        paths[0] === '/'
      );
    };

    if (showMessage && !notificationCenterEnabled) {
      if (!assistantExperimentEnabled) {
        setShowNotification(true);
        readMessage();
      } else if (canAutoOpenAssistant()) {
        history.push('/assistant');
        readMessage();
      }
    }
  }, [
    paths,
    showMessage,
    assistantExperimentEnabled,
    isTabletOrLarger,
    readMessage,
    history,
  ]);

  //https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
  useEffect(() => {
    if (prevIsOpen.current && isOpen !== prevIsOpen.current && !isOpen) {
      //assistant was open, and is now closed.
      buttonRef?.focus();
    }

    prevIsOpen.current = isOpen;
  }, [buttonRef, isOpen]);

  const handleButtonClick = () => {
    if (isOpen) {
      history.goBack();
    } else {
      history.push('/assistant');
    }

    if (showNotification && messageItem) {
      dismissNotification();
    }
    trackAssistantButtonClick({ is_opening: !isOpen });
  };

  const dismissNotification = () => {
    setShowNotification(false);
    readMessage();
  };

  const assistantHasNotifications = !notificationCenterEnabled && count > 0;

  const notificationString = t('assistant.notification', { count: count });

  const notificationLabel = assistantHasNotifications
    ? `- ${count} ${notificationString}`
    : '';

  const assistantIconText = !isOpen
    ? `${t('assistant.assistant')} ${notificationLabel}`
    : `${t('assistant.close_assistant')} ${notificationLabel}`;

  const entryClass = cx('assistant__entry', {
    'assistant__entry--open': isOpen,
    'assistant__entry--has-message': showMessage,
    search_bar_in_header: searchBarInHeaderEnabled,
  });

  const buttonClasses = cx(
    'assistant__entry__button',
    'pt-theme-light-bordercolor'
  );
  return isOpen && notificationCenterEnabled ? null : (
    <FocusLock group="assistant-lock" disabled={overlayIsActive || !isOpen}>
      <div className={entryClass}>
        <Tooltip
          aria-hidden
          text={assistantIconText}
          referenceElement={buttonRef}
        >
          <Button
            className={buttonClasses}
            onClick={handleButtonClick}
            aria-label={assistantIconText}
            ref={setButtonRef}
            style={{ backgroundColor: themeColor }}
            aria-expanded={Boolean(isOpen)}
          >
            {!isOpen ? <AssistantIcon /> : <Icon type="close" />}
            {assistantHasNotifications && (
              <>
                <span className="sr-only" role="status">
                  {t('assistant.notification_status', { count })}
                </span>
                <span className="inbox-count pt-theme-light-bgcolor">
                  {count}
                </span>
              </>
            )}
          </Button>
        </Tooltip>
        {showNotification && messageItem ? (
          <div className="assistant__entry__message">
            <AssistantLandingItem
              onClick={dismissNotification}
              item={messageItem}
            />
            <CircleTimer
              reverse
              duration={30000}
              onComplete={dismissNotification}
              onTick={null}
              color={lighten(0.1, themeColor)}
            >
              <Button
                onClick={() => dismissNotification()}
                icon="close"
                aria-label={t('common.close')}
              />
            </CircleTimer>
          </div>
        ) : null}
      </div>
    </FocusLock>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  count: assistantSelectors.inboxCount(state),
  messageItem: assistantSelectors.getMessageItem(state),
  dismissedMessages: assistantSelectors.getDismissedMessages(state),
  assistantExperimentEnabled:
    programSelectors.getAssistantHomePageExperimentEnabled(state),
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
  overlayIsActive: uiSelectors.getOverlayIsActive(state),
});

export default connect(mapStateToProps, null)(AssistantButton);
