import { Avatar } from '@socialchorus/shared-ui-components';
import axios from 'axios';
import debounce from 'lodash/debounce';
import {
  ChangeEventHandler,
  FocusEventHandler,
  JSXElementConstructor,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { uiOperations } from '../../../models/ui';
import { advocateSelectors } from '../../../models/advocate';
import { programMembershipSelectors } from '../../../models/program-membership';

import { Spinner } from '../../ui';

import { ID as NameRequiredDialogID } from '../../name-required-dialog';
import { ID as PrivateProfileDialogID } from '../../private-profile-dialog';

import { elWordPos, isLink, isMention } from '../../../lib/string-utils';
import TextValidator from '../../../lib/text-validator';
import { saveComment } from '../../../services/comment';

import { trackCommentEvent } from '../../../models/comments/analytics';
import { programSelectors } from '../../../models/program';

import CommentLinkPreview from '../../comments/comment-link-preview';
import { RootPatronState } from '../../../common/use-patron-selector';
import { useActiveElement } from '../../../hooks/useActiveElement';
import {
  Comment,
  CommentAssistantSuggestionSelection,
} from '../../../models/comments/types';
import { FlashMessage } from '../../comments/comment-flash-message';
import { Textarea } from '../../ui/text-input';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import { CommentAssistantSuggestion } from './comment-assistant-suggestion';
import CommentFormFooter from './comment-form-footer';
import { CommentFormHeader } from './comment-form-header';
import { CommentAssistantMobileMenu } from './comment-assistant-menus/mobile-menu';

const MAX_COMMENT_LENGTH = 500;

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type OwnProps = {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any;
  onError?: (opts: FlashMessage) => void;
  onReset?: () => void;
  onSubmit?: (comment: Comment) => void;
  onFormFocus?: FocusEventHandler;
  onFormBlur?: FocusEventHandler;
};

type CommentFormProps = RouteComponentProps<
  Record<string, string | undefined>,
  Record<string, string | undefined>
> &
  StateProps &
  DispatchProps &
  OwnProps;

type MentionText = {
  pos: [number, number];
  value: string;
};

interface LinkPreview {
  linkText: string | null;
  preview: boolean; // true if showing preview
  preventPreviews: string[];
}

const notifyNativeApps = () => {
  // Notify iOS app
  if (window?.webkit?.messageHandlers?.commentCreated !== undefined) {
    window.webkit.messageHandlers.commentCreated.postMessage();
  }
  // Notify Android app
  if (window?.commentCreated !== undefined) {
    window.commentCreated.postMessage();
  }
};

function CommentForm(props: CommentFormProps) {
  const { t } = useTranslation();

  const formEl = useRef<HTMLFormElement>(null);

  const [textareaEl, setTextareaEl] = useState<HTMLTextAreaElement | null>(
    null
  );
  const [commentText, setCommentText] = useState('');
  const [suggestion, setSuggestion] =
    useState<CommentAssistantSuggestionSelection | null>(null);
  const [disabled, setDisabled] = useState(props.disabled ?? false);
  const [original, setOriginal] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [mentionText, setMentionText] = useState<MentionText | null>(null);
  const [link, setLink] = useState<LinkPreview>({
    linkText: null,
    preview: false,
    preventPreviews: [],
  });
  const [isAssistantMobileMenuOpen, setIsAssistantMobileMenuOpen] =
    useState(false);
  const [shouldCheckForMention, setShouldCheckForMention] = useState(false);
  const [focusTextareaTimeout, setFocusTextareaTimeout] =
    useState<NodeJS.Timeout>();
  const [newCursorPos, setNewCursorPos] = useState<[number, number]>();

  const isSuggestionDisabled =
    disabled || submitting || suggestion !== null || commentText.length == 0;

  const submissionDisabled =
    disabled ||
    submitting ||
    original === commentText.trim() ||
    isSuggestionDisabled;

  const textValidator = useMemo(
    () =>
      new TextValidator(
        {
          preventHtml: true,
          preventWhiteSpaceOnly: true,
          minLength: 2,
          maxLength: MAX_COMMENT_LENGTH,
        },
        'Comment'
      ),
    []
  );

  const currentWordPos = useCallback(() => {
    if (!textareaEl) {
      return [0, 0];
    }
    return elWordPos(textareaEl);
  }, [textareaEl]);

  const currentWord = useCallback(() => {
    if (!textareaEl) {
      return [0, 0];
    }
    const posArr = elWordPos(textareaEl);
    return commentText.slice(posArr[0], posArr[1]);
  }, [commentText, textareaEl]);

  const focusForm = useCallback(() => {
    if (props.disabled) return;
    textareaEl?.focus();
  }, [props.disabled, textareaEl]);

  const addEmojiToText = useCallback(
    (emoji: string) => {
      setCommentText((text) => {
        const emojiEl = document.createElement('div');
        emojiEl.innerHTML = emoji;
        emoji = emojiEl.innerHTML;

        const pos = textareaEl?.selectionEnd;
        const emojiText =
          pos !== undefined && text[pos] !== ' ' ? emoji + ' ' : emoji;
        const newText = text.slice(0, pos) + emojiText + text.slice(pos);

        const newCursorPos = (pos ?? 0) + emojiText.length;
        setNewCursorPos([newCursorPos, newCursorPos]);

        setShowEmojis(false);
        return newText;
      });
    },
    [textareaEl?.selectionEnd]
  );

  const handleEmojiClick = useCallback(
    (emoji: string) => {
      addEmojiToText(emoji);
      requestIdleCallback(focusForm, { timeout: 300 });
    },
    [addEmojiToText, focusForm]
  );

  const addMentionToText = useCallback((mention: string) => {
    setMentionText((mentionText) => {
      if (mentionText) {
        const { pos } = mentionText;
        setCommentText((text) => {
          const mentionText = text[pos[1]] !== ' ' ? mention + ' ' : mention;
          return text.slice(0, pos[0]) + mentionText + text.slice(pos[1]);
        });

        const newCursorPos = pos[0] + mention.length + 1;
        setNewCursorPos([newCursorPos, newCursorPos]);
      }
      return null;
    });
  }, []);

  const handleMentionClick = useCallback(
    (mention: string) => {
      addMentionToText(mention);
      focusForm();
    },
    [addMentionToText, focusForm]
  );

  const resetForm = useCallback(() => {
    setCommentText('');
    setMentionText(null);
    setShowEmojis(false);
    setSubmitting(false);
    setLink({
      linkText: null,
      preview: false,
      preventPreviews: [],
    });
  }, []);

  const handleEditCancel = useCallback(() => {
    resetForm();
    props.onReset?.();
  }, [props.onReset, resetForm]);

  const handleDocumentClick: EventListener = useCallback(
    (e) => {
      if (!formEl.current) return; // For IE11, as click listener still invokes after unmount
      if (
        !formEl.current.contains(e.target as Node) ||
        e.target === textareaEl
      ) {
        setShowEmojis(false);
        setMentionText(null);
      }
    },
    [formEl, textareaEl]
  );

  const addDocumentClickListener = useCallback(() => {
    ['click'].forEach((e) => {
      document.addEventListener(e, handleDocumentClick);
    });
  }, [handleDocumentClick]);

  const removeDocumentClickListener = useCallback(() => {
    ['click'].forEach((e) => {
      document.removeEventListener(e, handleDocumentClick);
    });
  }, [handleDocumentClick]);

  const checkForMention = useCallback(() => {
    const posArr = currentWordPos();
    const text = commentText.slice(posArr[0], posArr[1]);
    const mention = isMention(text);

    if (mention) {
      setMentionText({
        pos: [posArr[0], posArr[1]],
        value: text,
      });
      setShowEmojis(false);
      addDocumentClickListener();
    } else {
      setMentionText(null);
      removeDocumentClickListener();
    }
  }, [
    addDocumentClickListener,
    commentText,
    currentWordPos,
    removeDocumentClickListener,
  ]);

  const checkForLink = useCallback(() => {
    const posArr = currentWordPos();
    const text = commentText.slice(posArr[0], posArr[1]);
    const textIsLink = isLink(text);

    if (textIsLink) {
      setLink((link) => {
        // prevent update if already previewing a link
        if (
          text &&
          link.preventPreviews.some((el) => el === text) &&
          !link.preview
        ) {
          return {
            linkText: text,
            preview: false,
            preventPreviews: link.preventPreviews,
          };
        }
        return link;
      });
    } else {
      // text no longer a link, not previewing, and link text present then show preview
      setLink((link) => {
        if (link.linkText && !link.preview) {
          return {
            linkText: link.linkText,
            preview: true,
            preventPreviews: link.preventPreviews,
          };
        }
        return link;
      });
    }
  }, [commentText, currentWordPos]);

  const debouncedCheckForMention = useMemo(
    () => debounce(() => setShouldCheckForMention(true), 250),
    []
  );
  const debouncedCheckForLink = useMemo(
    () => debounce(checkForLink, 250),
    [checkForLink]
  );

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      const text = e.target.value || '';
      setCommentText(text);
      debouncedCheckForMention();
      if (!link.preview) debouncedCheckForLink();
    },
    [debouncedCheckForLink, debouncedCheckForMention, link.preview]
  );

  const handleError = useCallback(
    (e: unknown) => {
      let errorText = '';

      if (axios.isAxiosError(e)) {
        errorText = e.response?.data.message;
      }
      errorText = errorText || 'There was an error submitting';

      props.onError?.({ text: errorText, type: 'error' });

      setSubmitting(false);
    },
    [props.onError]
  );

  const commentTextFormatted = useCallback(() => {
    let result = commentText;
    link.preventPreviews.forEach((link) => {
      // Discourse will show markdown formatted links inline and not preview
      result = result.replace(link, `[${link}](${link})`);
    });
    return result;
  }, [commentText, link]);

  const submitComment = useCallback(() => {
    const errors = textValidator.validate(commentText);

    if (errors.length) {
      return props.onError?.({ text: errors[0], type: 'error' }); // only supports a single error at the moment
    }

    setSubmitting(true);

    saveComment({
      contentId: props.context.contentId,
      commentId: props.context.commentId,
      replyToId: props.context.replyToId,
      body: commentTextFormatted(),
    })
      .then(
        (res): Comment => ({
          ...res.data.data.attributes,
          id: res.data.data.id,
          replyToId: props.context.replyToId,
        })
      )
      .then((comment) => {
        trackCommentEvent('submit', comment, !!comment.replyToId);
        notifyNativeApps();

        props.onSubmit?.(comment);

        resetForm();
      })
      .catch((e) => handleError(e));
  }, [
    commentText,
    commentTextFormatted,
    handleError,
    props.context.contentId,
    props.context.commentId,
    props.context.replyToId,
    props.onError,
    props.onSubmit,
    resetForm,
    textValidator,
  ]);

  const handleSubmit = useCallback(
    (e?: SubmitEvent) => {
      e?.preventDefault?.();

      const presubmitDialog =
        props.advocateNameMissing && props.profileEditNameEnabled
          ? NameRequiredDialogID
          : props.advocateIsPrivate && !props.advocateHidPrivateWarning
          ? PrivateProfileDialogID
          : null;

      if (presubmitDialog) {
        props.addOverlay(presubmitDialog, {
          advocateId: props.advocateId,
          continue:
            presubmitDialog === NameRequiredDialogID
              ? handleSubmit // run submit again to check profile conditions
              : submitComment,
        });

        return;
      }

      submitComment();
    },
    [
      props.addOverlay,
      props.advocateHidPrivateWarning,
      props.advocateId,
      props.advocateIsPrivate,
      props.advocateNameMissing,
      props.profileEditNameEnabled,
      submitComment,
    ]
  );

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      if (!e.shiftKey && !mentionText && e.key === 'Enter') {
        e.preventDefault();

        if (!submissionDisabled) {
          handleSubmit();
        }
      }

      if (e.key === 'Escape') {
        setShowEmojis(false);
        setMentionText(null);

        removeDocumentClickListener();
      }
    },
    [handleSubmit, mentionText, removeDocumentClickListener, submissionDisabled]
  );

  const preventLinkPreview = useCallback(() => {
    setLink((link) => {
      const newPreviews = link.linkText
        ? link.preventPreviews.concat([link.linkText])
        : link.preventPreviews;

      return {
        linkText: null,
        preview: false,
        preventPreviews: newPreviews,
      };
    });
  }, []);

  const toggleEmojiDrawer = useCallback(() => {
    setShowEmojis((showEmojis) => {
      if (showEmojis) {
        removeDocumentClickListener();
      } else {
        addDocumentClickListener();
      }
      return !showEmojis;
    });
    setMentionText(null);
  }, []);

  const handleEmojiIconClick = useCallback(() => {
    if (props.disabled) return false;
    toggleEmojiDrawer();
  }, [props.disabled, toggleEmojiDrawer]);

  const showMentionDrawer = useCallback(() => {
    const currWord = currentWord();

    setCommentText((commentText) => {
      if (currWord === '' || currWord === ' ') {
        return commentText + '@';
      } else if (currWord !== '@') {
        return commentText + ' @';
      }
      return commentText.substring(0, commentText.length - 2);
    });
    setShouldCheckForMention(true);

    focusForm();
  }, [currentWord, focusForm]);

  const handleMentionIconClick = useCallback(() => {
    if (props.disabled) return false;
    showMentionDrawer();
  }, [props.disabled, showMentionDrawer]);

  const handleCommentAssistantSuggestion = (
    suggestion: CommentAssistantSuggestionSelection
  ) => {
    setIsAssistantMobileMenuOpen(false);
    setSuggestion(suggestion);
  };

  const handleSuggestionChanged = useCallback(
    (newComment: string) => {
      setCommentText(newComment);
    },
    [setCommentText]
  );

  const handleSuggestionClosed = () => {
    setSuggestion(null);
  };

  useEffect(() => {
    if (props.history.location.hash === '#comments') {
      // Focus on the textarea after the window.scroll is completed
      // see src/components/v2/content-detail/content-detail.tsx
      setFocusTextareaTimeout((timeout) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        return setTimeout(focusForm, 500);
      });
    }
  }, [focusForm, props.history.location.hash]);

  useEffect(() => {
    return () => {
      if (focusTextareaTimeout) {
        clearTimeout(focusTextareaTimeout);
      }
    };
  }, [focusTextareaTimeout]);

  useEffect(() => {
    return () => {
      removeDocumentClickListener();
    };
  }, [removeDocumentClickListener]);

  useEffect(() => {
    setDisabled(props.disabled ?? false);
  }, [props.disabled]);

  useEffect(() => {
    setCommentText(props.context.commentText || '');
    setOriginal(props.context.commentText);
  }, [props.context.commentText]);

  // Does this fix a bug? Yes. (https://firstup-io.atlassian.net/browse/FE-10450)
  // How? ¯\_(ツ)_/¯
  // I suspect some issue with react-textarea-autosize's management of the ref when the text content is updated.
  // (only happens on edit and not reply, seemingly due to the text area's textContent is updated at the same time)
  // For whatever reason, useCallback/useEffect does not detect that the ref has been updated, when it has. (and so focus was called on the wrong element)
  // So, to solve this, we can use an immediate timeout in order to force this focus to be the last thing that happens in the render loop.
  const _magical_focusForm = useCallback(() => {
    setFocusTextareaTimeout((timeout) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      return setTimeout(focusForm, 1);
    });
  }, [focusForm]);

  useEffect(() => {
    if ('edit' === props.context.action) {
      _magical_focusForm();
    }
  }, [_magical_focusForm, props.context.action, props.context.commentText]);
  //also want commentText prop (only prop) to trigger this, as it represents the user clicking on 'edit', relevant when theres more than one owned comment on the pag.e

  useEffect(() => {
    if ('reply' === props.context.action) {
      focusForm();
    }
  }, [focusForm, props.context.action]);

  useEffect(() => {
    if (shouldCheckForMention) {
      checkForMention();
      setShouldCheckForMention(false);
    }
  }, [checkForMention, shouldCheckForMention]);

  const activeElement = useActiveElement();

  useEffect(() => {
    if (!textareaEl || !newCursorPos || activeElement !== textareaEl) {
      return;
    }

    textareaEl.setSelectionRange(newCursorPos[0], newCursorPos[1]);
    setNewCursorPos(undefined);
  }, [activeElement, newCursorPos, textareaEl]);

  return (
    <form
      className="comment-form"
      ref={formEl}
      onFocus={props.onFormFocus}
      onBlur={props.onFormBlur}
    >
      <CommentFormHeader
        context={props.context}
        showEmojis={showEmojis}
        mentionText={mentionText?.value}
        onEmojiClick={handleEmojiClick}
        onMentionClick={handleMentionClick}
        onCancelClick={handleEditCancel}
      />

      {isAssistantMobileMenuOpen && (
        <CommentAssistantMobileMenu
          onClose={() => setIsAssistantMobileMenuOpen(false)}
          onSuggestionSelected={handleCommentAssistantSuggestion}
        />
      )}

      <div className="comment-form__input-container">
        <div className="comment-form__input-avatar">
          <Avatar
            size="medium"
            shape="circle"
            imgSrc={props.advocate.avatar.url || undefined}
          />
        </div>
        {suggestion ? (
          <CommentAssistantSuggestion
            selectedSuggestion={suggestion}
            comment={commentText}
            onSuggestionChanged={handleSuggestionChanged}
            onSuggestionClosed={handleSuggestionClosed}
            contentId={props.context.contentId}
          >
            <Textarea
              value={commentText}
              placeholder={t('comments.write.comment')}
              disabled={disabled || submitting}
              aria-disabled={disabled || submitting}
              onChange={!submitting ? handleChange : undefined}
              onKeyDown={!submitting ? handleKeyDown : undefined}
              ref={setTextareaEl}
            />
          </CommentAssistantSuggestion>
        ) : (
          <Textarea
            value={commentText}
            placeholder={t('comments.write.comment')}
            disabled={disabled || submitting}
            aria-disabled={disabled || submitting}
            className="comment-form__input"
            onChange={!submitting ? handleChange : undefined}
            onKeyDown={!submitting ? handleKeyDown : undefined}
            ref={setTextareaEl}
          />
        )}
      </div>

      {link.preview ? (
        <CommentLinkPreview
          linkText={link.linkText}
          handleClose={preventLinkPreview}
        />
      ) : null}

      <CommentFormFooter
        commentLength={commentText.length}
        commentLengthMax={MAX_COMMENT_LENGTH}
        disabled={submissionDisabled}
        suggestionDisabled={isSuggestionDisabled}
        onEmojiIconClick={handleEmojiIconClick}
        onMentionIconClick={handleMentionIconClick}
        onSuggestionSelected={handleCommentAssistantSuggestion}
        onSubmit={handleSubmit}
        setIsAssistantMobileMenuOpen={setIsAssistantMobileMenuOpen}
      />

      {!props.newContentDetail && submitting ? <Spinner /> : null}
    </form>
  );
}

const mapStateToProps = (state: RootPatronState) => ({
  advocate: advocateSelectors.getAdvocate(state),
  advocateId: advocateSelectors.getAdvocateId(state),
  advocateNameMissing: advocateSelectors.getAdvocateNameMissing(state),
  advocateIsPrivate: advocateSelectors.getAdvocateIsPrivate(state),
  advocateHidPrivateWarning:
    programMembershipSelectors.getProgramMembershipHidePrivateWarning(state),
  profileEditNameEnabled: programSelectors.getProfileEditNameEnabled(state),
  newContentDetail: getFeatureFlag(state, Feature.CONTENT_DETAIL_NEW),
});

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
};

export default compose<JSXElementConstructor<OwnProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CommentForm);
