import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  Checkbox,
  Icon,
  NotificationCard,
} from '@socialchorus/shared-ui-components';
import {
  BulkEditAction,
  Notification,
} from '../../../models/notifications/types';
import styles from '../notification-center.module.scss';
import { NotificationContext } from '../context';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bulkEditNotifications } from '../../../services/notifications';
import { notificationCountsState } from '../../../models/notifications/recoil-state';
import { useTranslation } from 'react-i18next';
import useScreenSize from '../../../common/use-screen-size';
import { useLinkNavigation } from '../../../common/use-link-navigation';
import useAssistantActionHandlers from '../../../common/use-assistant-action-handlers';

export const NotificationCardWrapper: React.FC<{
  notification: Notification;
}> = ({ notification }) => {
  const { t } = useTranslation();
  const { getDeeplink } = useAssistantActionHandlers();
  const url = notification?.action?.navigation
    ? getDeeplink(notification.action.navigation).url
    : undefined;
  const getNavigation = useLinkNavigation();

  const queryClient = useQueryClient();
  const {
    tabs: {
      activeTab: [activeTab],
    },
    multiSelect: {
      enabled: [multiSelectEnabled],
      selectAll: [selectAll, setSelectAll],
      selectedItems: [multiSelectItems, setMultiSelectItems],
    },
    search: {
      query: [searchQuery],
    },
  } = useContext(NotificationContext);
  const setNotificationCounts = useSetRecoilState(notificationCountsState);

  const { isMobile } = useScreenSize();

  //when the notification is clicked, we mark it as opened and then perform the action. The most common action is navigation.
  //(exceptions are things like welcome/test/ect)
  //this means that in the common case, we wont be on the notification feed page when the mark API response is returned.
  //for that reason, we dont bother with optimistic updates here, nor do we want to present errors/sucess to the user.
  const { mutate: markAsRead } = useMutation({
    mutationFn: async () =>
      bulkEditNotifications({
        ids: [notification.id],
        action: BulkEditAction.OPEN,
      }),
    onSuccess: (data) => {
      setNotificationCounts(data.data.counts);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications', activeTab],
      });
    },
  });

  const selected = useMemo(
    () => multiSelectItems.some((item) => item.id === notification.id),
    [multiSelectItems, notification.id]
  );

  const setSelected = useCallback(
    (checked: boolean) => {
      if (checked && !selected) {
        setMultiSelectItems((prev) => [...prev, notification]);
      }
      if (!checked && selected) {
        setMultiSelectItems((prev) =>
          prev.filter((item) => item.id !== notification.id)
        );
      }
    },
    [selected, notification, setMultiSelectItems]
  );

  const handleCheckedChange = useCallback(
    (checked: boolean) => {
      setSelected(checked);
      if (!checked && selectAll === true) {
        setSelectAll('indeterminate');
      }
    },
    [selectAll, setSelected, setSelectAll]
  );

  const handleClick = useCallback(() => {
    markAsRead();
  }, [markAsRead]);

  useEffect(() => {
    if (multiSelectEnabled && selectAll === true) {
      handleCheckedChange(true);
    }
  }, [multiSelectEnabled, selectAll, handleCheckedChange]);

  return (
    <div className={styles.notificationCardContainer}>
      {multiSelectEnabled && (
        <Checkbox
          checked={selected}
          className={styles.checkbox}
          onCheckedChange={(checked) => handleCheckedChange(!!checked)}
        />
      )}
      <NotificationCard.Root
        markUnread={!notification.read}
        onClick={handleClick}
        href={url ? getNavigation(url, 'replace') : undefined} //if this is undefined, will be a <button> vs an <a>
      >
        {!isMobile && (
          <NotificationCard.Avatar
            imgSrc={notification.avatar?.url || ''}
            alt={notification.avatar?.alt_text || ''}
          />
        )}
        <NotificationCard.Body
          title={notification.heading || ''}
          description={notification.subheading}
          quotation={notification.preview}
        >
          {searchQuery && notification.archived && (
            <NotificationCard.TagsGroup>
              <NotificationCard.Tag
                label={t('notification_center.tags.archived')}
              />
            </NotificationCard.TagsGroup>
          )}
          {notification.labels.important.length > 0 && (
            <NotificationCard.TagsGroup>
              {notification.labels.important.map((tag) => (
                <NotificationCard.Tag
                  key={tag}
                  label={tag}
                  variant="negative"
                />
              ))}
            </NotificationCard.TagsGroup>
          )}
          {notification.labels.action_required.length > 0 && (
            <NotificationCard.TagsGroup>
              {notification.labels.action_required.map((tag) => (
                <NotificationCard.Tag key={tag} label={tag} variant="warning" />
              ))}
            </NotificationCard.TagsGroup>
          )}
        </NotificationCard.Body>
        <NotificationCard.Footer timestamp={new Date(notification.created_at)}>
          {notification.labels.important.length > 0 && (
            <Icon className="text-red-full-value">error</Icon>
          )}
          {notification.labels.action_required.length > 0 && (
            <Icon className="text-orange-full-value">ads_click</Icon>
          )}
        </NotificationCard.Footer>
      </NotificationCard.Root>
    </div>
  );
};
