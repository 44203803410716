import React from 'react';
import { Container } from '@socialchorus/shared-ui-components';

import styles from './assistant.module.scss';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../common/use-skip-to-content';
import { Trans } from 'react-i18next';
import { AssistantAvatar } from './assistant-avatar';

interface AssistantWelcomeMessageProps {
  statusRef: React.RefObject<HTMLDivElement>;
}

const MESSAGES = ['greeting', 'description', 'help_ways'];

export const AssistantWelcomeMessage: React.FC<
  AssistantWelcomeMessageProps
> = ({ statusRef }) => {
  return (
    <div className={styles.welcomeDialog}>
      {MESSAGES.map((message, index) => (
        <div className={styles.assistantMessage} key={`message-${index}`}>
          <div className={styles.author}>
            {index === 0 && <AssistantAvatar />}
          </div>
          <div className={styles.messages}>
            <Container
              fullWidth
              {...(index === 0 && {
                id: DEFAULT_SKIP_TO_CONTENT_ID,
                ref: statusRef,
              })}
              shadow="light"
              shape="medium"
              variant="border"
              className={styles.welcomeMessage}
            >
              <Trans
                i18nKey={`assistant.welcome.${message}`}
                components={{ strong: <strong />, br: <br /> }}
              />
            </Container>
          </div>
        </div>
      ))}
    </div>
  );
};
