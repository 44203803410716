import { connect } from 'react-redux';
import { RootPatronState } from '../../common/use-patron-selector';
import { Message } from '../../models/messages/types';
import { programSelectors } from '../../models/program';
import { useRecoilState } from 'recoil';
import { messageCompletedState } from '../../models/messages/recoil-state';
import { useMemo, useRef } from 'react';
import { Monitor } from '../content/content-completion/types';
import contentCompletion from '../content/content-completion';
import useIframeResize from '../../common/useIframeResize';

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  messageId: Message['id'];
};

type MessageFrameProps = StateProps & OwnProps;

const MessageFrame = ({ messageId, programId }: MessageFrameProps) => {
  const iframeMessageSrc = `/embed/messages/${messageId}?program=${programId}`;
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const handleIframeRefChange = (node: HTMLIFrameElement | null) => {
    iframeRef.current = node;
  };

  const {
    height,
    ref: resizedIframeRef,
    hasResized,
  } = useIframeResize({
    onRefChange: handleIframeRefChange,
  });

  const [isContentCompleted, setIsContentCompleted] = useRecoilState(
    messageCompletedState(messageId)
  );

  const completionMonitor = useMemo(() => {
    if (isContentCompleted) return undefined;

    const handleContentCompleted = (monitor: Monitor) => {
      if (iframeRef.current) {
        monitor.detach();
      }
      setIsContentCompleted(true);
    };

    const monitor = contentCompletion['article'];
    return monitor ? new monitor(handleContentCompleted) : undefined;
  }, [messageId, isContentCompleted, setIsContentCompleted]);

  const iframe = iframeRef.current;

  if (completionMonitor && iframe) {
    completionMonitor.attach(iframe);
  }

  return (
    <div className="message-frame">
      <iframe
        allowFullScreen
        src={iframeMessageSrc}
        title={messageId}
        ref={resizedIframeRef}
      />
    </div>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  programId: programSelectors.getProgramId(state),
});

export default connect(mapStateToProps, null)(MessageFrame);
