import { ComponentProps, JSXElementConstructor, useMemo } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { LocationDescriptorObject } from 'history';

import AssistantError from './assistant-error';
import Home from './assistant-home';
import Explore from './assistant-explore';
import Notifications from './assistant-notifications';
import History from './assistant-history';
import CommandsList from './assistant-commands-list';
import SummaryDetail from './assistant-summary-detail';
import Integration from './assistant-integration';
import Todos from './assistant-todos';
import Contents from './assistant-contents';
import Profiles from './assistant-profiles';
import Channels from './assistant-channels';
import Resources from './assistant-resources';
import CommandRunner from './assistant-command-runner';
import AnsweredPolls from './assistant-answered-polls';
import ErrorBoundary from '../../components/error-boundary';

import useAssistantFetcher, {
  AssistantDataType,
} from '../../common/use-assistant-fetcher';

import { uiSelectors } from '../../models/ui';
import NestedMemoryRouter from '../../components/nested-router/nested-memory-router';

type StateProps = {
  overlayIsActive: ReturnType<typeof uiSelectors.getOverlayIsActive>;
};

type AssistantProps = RouteComponentProps &
  StateProps & {
    goBack: LocationDescriptorObject | null;
  };

export function AssistantRoot({ history, match, goBack }: AssistantProps) {
  const handleCloseClick = () => {
    history.goBack();
  };

  const onRouteExit: ComponentProps<
    typeof NestedMemoryRouter
  >['onRouteExit'] = (nextLocation) => {
    if (nextLocation.pathname === goBack?.pathname) {
      handleCloseClick();
    } else {
      history.replace(nextLocation);
    }
  };

  // Data needed by every assistant screen
  const { isError } = useAssistantFetcher({
    itemTypes: [
      AssistantDataType.INTEGRATIONS,
      AssistantDataType.BOOTSTRAP,
      AssistantDataType.COMMANDS,
    ],
  });

  const assistantRoutes: {
    path: string;
    Component: JSXElementConstructor<RouteComponentProps>;
  }[] = useMemo(() => {
    return [
      { path: `${match.path}`, Component: Home },
      { path: `${match.path}/commandcenter`, Component: Explore },
      { path: `${match.path}/notifications`, Component: Notifications },
      { path: `${match.path}/commands/internal`, Component: SummaryDetail },
      { path: `${match.path}/commands_list`, Component: CommandsList },
      { path: `${match.path}/commands`, Component: History },
      { path: `${match.path}/todos`, Component: Todos },
      { path: `${match.path}/todos/:todoId`, Component: Todos },
      { path: `${match.path}/polls/answered`, Component: AnsweredPolls },
      {
        path: `${match.path}/integrations/:integrationId`,
        Component: Integration,
      },
      {
        path: `${match.path}/service/:integrationId`,
        Component: Integration,
      },
      {
        path: `${match.path}/integrations/:integrationId/command/:command`,
        Component: CommandRunner,
      },
      {
        path: `${match.path}/service/:integrationId/command/:command`,
        Component: CommandRunner,
      },
      { path: `${match.path}/resources`, Component: Resources },
      { path: `${match.path}/contents`, Component: Contents },
      { path: `${match.path}/profiles`, Component: Profiles },
      { path: `${match.path}/channels`, Component: Channels },
    ];
  }, [match.path]);
  return (
    <NestedMemoryRouter matchPath={match.path} onRouteExit={onRouteExit}>
      {isError ? (
        <AssistantError />
      ) : (
        <Switch>
          {assistantRoutes.map(({ path, Component }) => (
            <Route
              path={path}
              render={(props) => (
                <ErrorBoundary fallbackComponent={AssistantError}>
                  <Component {...props} />
                </ErrorBoundary>
              )}
              exact
              key={path}
            />
          ))}
        </Switch>
      )}
    </NestedMemoryRouter>
  );
}
