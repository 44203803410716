import React, { useEffect } from 'react';
import { Container } from '@socialchorus/shared-ui-components';

import { AssistantIntegration } from '../../models/assistant/selectors';
import ViewTrigger from '../../components/view-trigger';
import { AssistantLink } from '../../components/assistant/assistant-link';
import Spinner from '../../components/ui/spinner';
import useAssistantFetcher, {
  AssistantDataType,
} from '../../common/use-assistant-fetcher';
import { usePatronSelector } from '../../common/use-patron-selector';
import { assistantSelectors } from '../../models/assistant';
import { programSelectors } from '../../models/program';
import {
  trackAssistantExploreLoad,
  trackAssistantActionsClick,
  trackAssistantActionsView,
} from '../../models/assistant/analytics';
import styles from './assistant.module.scss';

const AssistantIntegrationsList: React.FC = () => {
  const { isFetching } = useAssistantFetcher({
    itemTypes: [AssistantDataType.INTEGRATIONS],
  });

  const integrations = usePatronSelector(assistantSelectors.getIntegrations);
  const labels = usePatronSelector(assistantSelectors.getLabels);
  const programThemeIconUrl = usePatronSelector(
    programSelectors.getProgramThemeIcon
  );

  useEffect(() => {
    trackAssistantExploreLoad();
  }, []);

  const onIntegrationView = (
    integration: AssistantIntegration,
    position: number
  ) => {
    trackAssistantActionsView({
      position,
      name: integration.subject.title,
      integration_id: integration.id,
    });
  };

  const onIntegrationClick = (
    integration: AssistantIntegration,
    position: number
  ) => {
    trackAssistantActionsClick({
      position,
      name: integration.subject.title,
      integration_id: integration.id,
    });
  };

  const displayedIntegrations =
    integrations?.filter(
      (integration) => integration.subject.integrationType != 'link'
    ) || [];

  return (
    <Spinner center loading={isFetching}>
      <div className="assistant__integrations-list">
        <h3 className="assistant__subtitle">{labels.apps}</h3>
        {displayedIntegrations.map((integration, position) => (
          <Container
            key={integration.id}
            fullWidth
            shadow="light"
            shape="medium"
            variant="border"
            className={styles.assistantApp}
          >
            <ViewTrigger
              onInview={() => {
                onIntegrationView(integration, position);
              }}
            />
            <AssistantLink
              className={styles.assistantAppSubject}
              onClick={() => {
                onIntegrationClick(integration, position);
              }}
              to={{
                pathname: `/assistant/service/${integration.id}`,
                state: { referer: 'explorer' },
              }}
            >
              <img
                alt={integration.subject.title}
                className={styles.assistantAppIcon}
                src={
                  integration.subject.image_url ||
                  programThemeIconUrl ||
                  undefined
                }
              />
              <div className={styles.assistantAppContent}>
                <p className={styles.assistantAppTitle}>
                  {integration.subject.title}
                </p>
                <p className={styles.assistantAppDescription}>
                  {integration.subject.description}
                </p>
              </div>
            </AssistantLink>
          </Container>
        ))}
      </div>
    </Spinner>
  );
};

export default AssistantIntegrationsList;
