import MessageFrame from '../../../components/messages/message-frame';
import { AcknowledgementFooter } from '../../../components/messages/acknowledgement-footer';
import './message-detail.scss';

const MessageDetail = ({ messageId }) => {
  const className = 'message-detail message-detail--article';

  return (
    <>
      <article className={className}>
        <MessageFrame messageId={messageId} />
      </article>
      <AcknowledgementFooter messageId={messageId} />
    </>
  );
};

export default MessageDetail;
