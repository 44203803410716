import { useCallback, useContext, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  IconButton,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
  Icon,
  CheckboxLabel,
} from '@socialchorus/shared-ui-components';
import styles from './../notification-center.module.scss';
import { MobileOverlayKeys, NotificationContext } from '../context';
import { NotificationFilterTags } from '../../../models/notifications/types';
import useScreenSize from '../../../common/use-screen-size';

type FilterTagToIconProps = {
  tag: NotificationFilterTags;
  colored?: boolean;
  compact?: boolean;
};

export const mapFilterTagToIcon = ({
  tag,
  colored = false,
  compact = false,
}: FilterTagToIconProps) => {
  switch (tag) {
    case NotificationFilterTags.ACTION_REQUIRED:
      return (
        <Icon
          size={compact ? 16 : 24}
          className={cx(colored && 'text-orange-full-value')}
        >
          ads_click
        </Icon>
      );
    case NotificationFilterTags.IMPORTANT:
      return (
        <Icon
          size={compact ? 16 : 24}
          className={cx(colored && 'text-red-full-value')}
        >
          error
        </Icon>
      );
    case NotificationFilterTags.READ:
      return <Icon size={compact ? 16 : 24}>drafts</Icon>;
    case NotificationFilterTags.UNREAD:
      return <Icon size={compact ? 16 : 24}>mark_email_unread</Icon>;
    case NotificationFilterTags.ARCHIVED:
      return <Icon size={compact ? 16 : 24}>inventory_2</Icon>;
    case NotificationFilterTags.UNARCHIVED:
      return <Icon size={compact ? 16 : 24}>unarchive</Icon>;
  }
};

type DesktopFilterDropdownProps = {
  container: HTMLElement | null | undefined;
  allowedOptions: NotificationFilterTags[];
};

const DesktopFilterDropdown = ({
  container,
  allowedOptions,
}: DesktopFilterDropdownProps) => {
  const { t } = useTranslation();

  const {
    filterTags: [filterTags, setFilterTags],
  } = useContext(NotificationContext);

  const handleOptionClick = useCallback(
    (tag: NotificationFilterTags) => {
      if (filterTags.includes(tag)) {
        setFilterTags(filterTags.filter((t) => t !== tag));
      } else {
        setFilterTags([...filterTags, tag]);
      }
    },
    [filterTags, setFilterTags]
  );

  return (
    <TooltipRoot>
      <TooltipContent
        description={t('notification_center.filter_by')}
        sideOffset={5}
        container={container}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <TooltipTrigger asChild>
            <IconButton iconName="filter_list" />
          </TooltipTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={styles.dropdownMenu}
          portalProps={{ container }}
        >
          <div
            className={cx(
              styles.menuTitle,
              'text-body-1 text-black-90 font-semibold'
            )}
          >
            {t('notification_center.filter_by')}
          </div>
          {allowedOptions.map((tag) => (
            <DropdownMenuCheckboxItem
              key={tag}
              id={`filter-dropdown-${tag}`}
              checked={filterTags.includes(tag)}
              onCheckedChange={() => handleOptionClick(tag)}
            >
              <CheckboxLabel
                className={styles.dropdownLabel}
                htmlFor={`filter-dropdown-${tag}`}
              >
                {mapFilterTagToIcon({ tag, colored: true })}
                <span className="text-body-1 text-black-90">
                  {t(`notification_center.tags.${tag}`)}
                </span>
              </CheckboxLabel>
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </TooltipRoot>
  );
};

type MobileFilterDropdownProps = {
  allowedOptions: NotificationFilterTags[];
};
const MobileFilterDropdown = ({
  allowedOptions,
}: MobileFilterDropdownProps) => {
  const {
    mobileOverlay: { open: openMobileOverlay },
  } = useContext(NotificationContext);

  return (
    <IconButton
      iconName="filter_list"
      onClick={() => {
        openMobileOverlay({
          screen: MobileOverlayKeys.FILTER,
          props: {
            allowedOptions,
          },
        });
      }}
    />
  );
};

type FilterDropdownProps = {
  container: HTMLElement | null | undefined;
  allowedOptions: NotificationFilterTags[] | undefined;
};
export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  container,
  allowedOptions,
}) => {
  const { isMobile } = useScreenSize();

  const {
    filterTags: [, setFilterTags],
  } = useContext(NotificationContext);

  useEffect(() => {
    if (!allowedOptions) return;

    //if allowedOptions changes, remove any selectedOptions that are no longer allowed
    setFilterTags((prevSelectedOptions) => {
      return prevSelectedOptions.filter((tag) => allowedOptions.includes(tag));
    });
  }, [allowedOptions, setFilterTags]);

  return !isMobile ? (
    <DesktopFilterDropdown
      container={container}
      allowedOptions={allowedOptions ?? []}
    />
  ) : (
    <MobileFilterDropdown allowedOptions={allowedOptions ?? []} />
  );
};
