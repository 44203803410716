import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import { AssistantIcon, Icon, StyleTag } from '../ui';
import { programSelectors } from '../../models/program';
import SiteHeaderLogo from './site-header-logo';
import SiteHeaderLinks, { NotificationCenterButton } from './site-header-links';
import SiteHeaderNav from './site-header-nav';
import SearchBar from '../search-bar/search-bar';
import './site-header.scss';
import { Feature, getFeatureFlag } from '../../models/features/features';
import SiteHeaderSubmit from './site-header-submit';
import { programMembershipSelectors } from '../../models/program-membership';
import { isInMSTeams } from '../../lib/teams-helper';
import { useEventListener } from 'usehooks-ts';
import SiteHeaderHomeIcon from './site-header-home-icon';
import { useRecoilValue } from 'recoil';
import { siteHeaderShouldBeInModalOverlay } from '../../models/ui/recoil-state';

const SiteHeaderDesktop = ({ logoOnly, searchBaseUrl, searchTerm }) => {
  // IE11 fix
  const ua = window.navigator.userAgent;
  const searchResults = [ua.indexOf('MSIE '), ua.indexOf('Trident/')];
  const isIE = searchResults.some((index) => index > -1);

  const newUIEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.NEW_UI_ENABLED)
  );

  return (
    <div className="container" style={isIE ? { maxWidth: '870px' } : undefined}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {!isInMSTeams ? (
          <SiteHeaderLogo logoOnly={logoOnly} />
        ) : (
          <SiteHeaderHomeIcon />
        )}

        {!logoOnly ? (
          <SearchBar baseUrl={searchBaseUrl} term={searchTerm} />
        ) : null}

        {!logoOnly ? (
          <div style={{ display: 'flex', position: 'relative' }}>
            <SiteHeaderLinks />
            <div className="nav-link-contain" style={{ paddingLeft: '2.3rem' }}>
              <SiteHeaderNav />
            </div>
            {/* Space for the assistant icon in narrow displays */}
            {!newUIEnabled && <div style={{ width: '73px' }}></div>}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const SiteHeaderMobile = ({ logoOnly, searchBaseUrl, searchTerm }) => {
  const [shouldShowSearchBar, setShouldShowSearchBar] = useState(false);

  const newUIEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.NEW_UI_ENABLED)
  );

  const notificationCenterEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.NOTIFICATION_CENTER_ENABLED)
  );

  const canSubmitContent = useSelector(
    programSelectors.getProgramCanSubmitContent
  );
  const membershipIsActive = useSelector(
    programMembershipSelectors.getProgramMembershipIsActive
  );

  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div className="container site-header__mobile">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {!logoOnly ? (
            <div
              className="nav-link-contain"
              style={{ paddingLeft: newUIEnabled ? undefined : '2.3rem' }}
            >
              <SiteHeaderNav />
              {newUIEnabled && !shouldShowSearchBar && !isInMSTeams && (
                <SiteHeaderLogo logoOnly={logoOnly} />
              )}
            </div>
          ) : null}

          {shouldShowSearchBar ? (
            <SearchBar
              baseUrl={searchBaseUrl}
              term={searchTerm}
              handleClose={() => setShouldShowSearchBar(false)}
            />
          ) : (
            <>
              {!newUIEnabled && <SiteHeaderLogo logoOnly={logoOnly} />}

              <div className="quick-links">
                {notificationCenterEnabled && membershipIsActive && (
                  <NotificationCenterButton />
                )}
                {membershipIsActive && canSubmitContent ? (
                  <SiteHeaderSubmit />
                ) : null}
                {!notificationCenterEnabled && (
                  <button
                    className="assistant-button"
                    onClick={() => history.push('/assistant')}
                  >
                    <AssistantIcon />
                  </button>
                )}
                <button
                  className="search-bar__search-button search_bar_in_header"
                  onClick={() => setShouldShowSearchBar(true)}
                  aria-label={t('nav_links.search')}
                >
                  <Icon>search</Icon>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const SiteHeaderSearchHeader = ({ loading, isMinimized, logoOnly }) => {
  const [term, setTerm] = useState('');
  const [baseUrl, setBaseUrl] = useState();
  const [isMobileMode, setIsMobileMode] = useState(false);
  const location = useLocation();

  const newUIEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.NEW_UI_ENABLED)
  );

  const className = cx(
    'site-header',
    'pt-theme-bgcolor',
    'search_bar_in_header',
    {
      'site-header--minimized': isMinimized,
      'site-header--logo-only': logoOnly,
      'site-header--v2': newUIEnabled,
    }
  );

  useEffect(() => {
    let searchType = 'contents',
      searchTerm = '',
      integrationId = '',
      command = '';

    const match = matchPath(location.pathname, {
      path: [
        '/search/:searchType/:searchTerm',
        '/search/:searchType(enterprise)/:integrationId/:command/:searchTerm?',
      ],
      exact: true,
      strict: false,
    });

    if (match) {
      searchType = match.params.searchType;
      searchTerm = match.params.searchTerm;
      integrationId = match.params.integrationId;
      command = match.params.command;

      setTerm(decodeURIComponent(searchTerm));
    }

    if (searchType === 'enterprise') {
      setBaseUrl(`/search/${searchType}/${integrationId}/${command}`);
    } else {
      setBaseUrl(`/search/${searchType}`);
    }
  }, [location.pathname]);

  function setMobileModeWhenMaxWidth799() {
    setIsMobileMode(
      window.matchMedia('only screen and (max-width: 799px)').matches
    );
  }

  useEffect(() => {
    setMobileModeWhenMaxWidth799();
  }, []);

  useEventListener('resize', setMobileModeWhenMaxWidth799);

  const EnabledSiteHeader = isMobileMode ? SiteHeaderMobile : SiteHeaderDesktop;

  return (
    <header className={className}>
      {!loading ? (
        <EnabledSiteHeader
          logoOnly={logoOnly}
          searchBaseUrl={baseUrl}
          searchTerm={term}
        />
      ) : null}
    </header>
  );
};

const SiteHeaderStandard = ({ loading, isMinimized, logoOnly }) => {
  const className = cx('site-header', 'pt-theme-bgcolor', {
    'site-header--minimized': isMinimized,
    'site-header--logo-only': logoOnly,
  });

  return (
    <header className={className}>
      {!loading ? (
        <div className="container">
          {!logoOnly ? (
            <div className="nav-link-contain">
              <SiteHeaderNav />
            </div>
          ) : null}

          <SiteHeaderLogo logoOnly={logoOnly} />

          {!logoOnly ? <SiteHeaderLinks /> : null}
        </div>
      ) : null}
    </header>
  );
};

const SiteHeader = ({
  loading,
  isHidden,
  isMinimized,
  logoOnly,
  searchBarInHeaderEnabled,
}) => {
  const newUIEnabled = useSelector((state) =>
    getFeatureFlag(state, Feature.NEW_UI_ENABLED)
  );

  const isEmbedded = window.self !== window.top;

  const EnabledSiteHeader =
    searchBarInHeaderEnabled || newUIEnabled
      ? SiteHeaderSearchHeader
      : SiteHeaderStandard;

  const hideHeaderFlag = useSelector((state) =>
    getFeatureFlag(state, Feature.HIDE_HEADER)
  );

  const headerInOverlayEnabled = useRecoilValue(
    siteHeaderShouldBeInModalOverlay
  );

  // https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
  const [visibleViewportHeight, setVisibleViewportHeight] = useState('100%');

  useEventListener('resize', () => {
    setVisibleViewportHeight(() => `${window.innerHeight}px`);
  });

  useEffect(() => {
    setVisibleViewportHeight(() => `${window.innerHeight}px`);
  }, []);

  // Header should be hidden if the feature flag is enabled and the page is embedded or
  // the header was hidden from a different component
  const shouldHide = (hideHeaderFlag && isEmbedded) || isHidden;
  const headerHeight = shouldHide ? '0px' : newUIEnabled ? '64px' : '100px';
  const headerHeightMobile = shouldHide
    ? '0px'
    : newUIEnabled
    ? '64px'
    : '50px';
  const headerHeightMinimized = shouldHide ? '0px' : '10px';
  const headerZLayer = headerInOverlayEnabled ? 3500 : 1000;

  return (
    <>
      <StyleTag>
        {`
          :root {
            --visible-viewport-height: ${visibleViewportHeight};
            --header-height: ${headerHeight};
            --header-height-mobile: ${headerHeightMobile};
            --header-height-min: ${headerHeightMinimized};
            --z-header-layer: ${headerZLayer};
          }
        `}
      </StyleTag>

      {shouldHide ? null : (
        <FocusLock group="nav-modal" disabled={!headerInOverlayEnabled}>
          <EnabledSiteHeader
            loading={loading}
            isMinimized={isMinimized}
            logoOnly={logoOnly}
          />
        </FocusLock>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isHidden: !!state.ui.hideHeader,
  isMinimized: !!state.ui.minimizeHeader,
  logoOnly: !!state.ui.logoOnlyHeader,
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
});

export default connect(mapStateToProps, null)(SiteHeader);
