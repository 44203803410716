import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { programSelectors } from '../../models/program';
import { programMembershipSelectors } from '../../models/program-membership';
import { uiOperations, uiSelectors } from '../../models/ui';
import { redirectToCommunitySwitch } from '../../lib/auth';
import AuthService from '../../services/auth';
import { Icon, NavLink, Button, Curtain } from '../ui';
import {
  Profile,
  TrendingIcon,
  SwitchIcon,
  StarFeaturedIcon,
  BookmarkIcon,
  PersonIcon,
  LatestIcon,
  LightBulb,
  ResourcesIcon,
  InviteIcon,
  HomeIcon,
  JoinedChannelsIcon,
  DiscoverIcon,
  Shield,
} from '../ui/icons';
import SiteHeaderLogo from './site-header-logo';
import { ID as InviteUserModalID } from '../invite-user-modal/invite-user-modal';
import { trackInviteClick } from '../../models/analytics';
import './site-header-nav.scss';
import { advocateSelectors } from '../../models/advocate';
import cx from 'classnames';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { useEventListener } from 'usehooks-ts';
import { usePopper } from 'react-popper';
import { RootPatronState } from '../../common/use-patron-selector';
import Tooltip from '../tooltip/tooltip';
import useMenuClick from '../../common/use-menu-click';
import useFeatureFlag from '../../common/use-feature-flag';

type SiteHeaderNavProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const SiteHeaderNav = ({
  resourcesEnabled,
  displayOverlay,
  advocateEntitlements,
  switchCommunitiesEnabled,
  forYouFeedEnabled,
  searchBarInHeaderEnabled,
  isMinimized,
}: SiteHeaderNavProps) => {
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMobileMode, setIsMobileMode] = useState(false);

  const notificationCenterEnabled = useFeatureFlag(
    Feature.NOTIFICATION_CENTER_ENABLED
  );
  //refs stored in state as per 'usePopper' docs
  const [hamburgerMenuButton, setHamburgerMenuButton] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(hamburgerMenuButton, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });
  const disablePopper = isMobileMode;
  const tabIndexProps = isMinimized ? { tabIndex: -1 } : {};

  const linkClasses = cx(
    'site-header-nav__link pt-hover-secondary-color pt-hover-secondary-fill'
  );
  const linkActiveClasses = cx('pt-secondary-color pt-secondary-fill');
  const navPaneClasses = cx('nav-pane', {
    search_bar_in_header: searchBarInHeaderEnabled,
  });

  function setMobileModeWhenMaxWidth799() {
    setIsMobileMode(
      window.matchMedia('only screen and (max-width: 799px)').matches
    );
  }

  useEffect(() => {
    setMobileModeWhenMaxWidth799();
  }, []);

  useEventListener('resize', setMobileModeWhenMaxWidth799);

  const toggleNav = () => {
    setIsNavOpen((isNavOpen) => !isNavOpen);
  };

  const signOut = ({ ignoreSSOLogout = false } = {}) => {
    return new AuthService().destroySession({ ignoreSSOLogout });
  };

  const siteHeaderRef = useMenuClick({
    disabled: !isNavOpen,
    onClick: toggleNav,
  });

  const handleInviteClick = () => {
    trackInviteClick();
    displayOverlay(InviteUserModalID);
  };

  const hasMultipleCommunities = () => {
    return advocateEntitlements.length > 1;
  };

  const handleSwitchCommClick = () => {
    signOut({ ignoreSSOLogout: true }).then(() => redirectToCommunitySwitch());
  };

  const newTopicsString = useSelector((state: RootPatronState) =>
    getFeatureFlag(state, Feature.CHANNELS_TO_TOPICS_ENABLED)
  );
  const joinChannelsString = newTopicsString
    ? 'navigation.follow_topics'
    : 'navigation.join_channels';
  const joinedChannelsString = newTopicsString
    ? 'discover.joined_topics'
    : 'discover.joined_channels';

  const menuButtonLabel = t('menu.menu');

  return (
    <div className="site-header-nav" ref={siteHeaderRef}>
      <Tooltip
        aria-hidden
        text={menuButtonLabel}
        options={{ placement: 'bottom' }}
      >
        <Button
          ref={setHamburgerMenuButton}
          className="no-link active"
          onClick={() => {
            toggleNav();
          }}
          aria-label={menuButtonLabel}
          {...tabIndexProps}
        >
          <Icon type="menu" />
        </Button>
      </Tooltip>

      {isNavOpen ? (
        <>
          <Curtain
            onClick={() => {
              toggleNav();
            }}
          />

          <div
            className={navPaneClasses}
            ref={setPopperElement}
            style={!disablePopper ? styles.popper : {}}
            {...(!disablePopper ? attributes.popper : [])}
          >
            <div className="nav-pane__left-pane">
              <div className="nav-pane__nav-group">
                <div>{t('navigation.personalize_experience')}</div>
                <p>{t(joinChannelsString)}</p>
              </div>
              <ul className="nav-pane__nav-group">
                <li>
                  <NavLink
                    className={linkClasses}
                    activeClassName={linkActiveClasses}
                    href="/"
                    action="push"
                    exact
                    aria-label={t('nav_links.home')}
                  >
                    <HomeIcon className={'mr-10'} />
                    <span className="title">{t('nav_links.home')}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={linkClasses}
                    activeClassName={linkActiveClasses}
                    href="/discover"
                    action="push"
                    exact
                    aria-label={t('nav_links.discover')}
                  >
                    <DiscoverIcon className={'mr-10'} />
                    <span className="title">{t('nav_links.discover')}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={linkClasses}
                    activeClassName={linkActiveClasses}
                    href="/account/channels"
                    action="push"
                    exact
                    aria-label={t(joinedChannelsString)}
                  >
                    <JoinedChannelsIcon className={'mr-10'} />
                    <span className="title">{t(joinedChannelsString)}</span>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="nav-pane__right-pane">
              <div className="header-logo pt-theme-bgcolor">
                <SiteHeaderLogo nameOnly={true} />
              </div>
              <ul className="nav-pane__nav-group">
                {searchBarInHeaderEnabled && isMobileMode ? (
                  <>
                    <li className="item-profile">
                      <NavLink
                        className={linkClasses}
                        activeClassName={linkActiveClasses}
                        href="/account/profile"
                        action="push"
                        exact
                        aria-label={t('screens.account.profile.title')}
                      >
                        <Profile className={'mr-10'} aria-hidden="true" />
                        <span className="title">
                          {t('screens.account.profile.title')}
                        </span>
                      </NavLink>
                    </li>
                    {notificationCenterEnabled && (
                      <li className="item-assistant">
                        <NavLink
                          className={linkClasses}
                          activeClassName={linkActiveClasses}
                          href="/assistant"
                          action="push"
                          exact
                          aria-label={t('assistant.assistant')}
                        >
                          <LightBulb className={'mr-10'} aria-hidden="true" />
                          <span className="title">
                            {t('assistant.assistant')}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    <li className="item-discover">
                      <NavLink
                        className={linkClasses}
                        activeClassName={linkActiveClasses}
                        href="/discover"
                        action="push"
                        exact
                        aria-label={t('nav_links.discover')}
                      >
                        <DiscoverIcon className={'mr-10'} />
                        <span className="title">{t('nav_links.discover')}</span>
                      </NavLink>
                    </li>
                  </>
                ) : null}
                {resourcesEnabled ? (
                  <li className="item-resources">
                    <NavLink
                      className={linkClasses}
                      activeClassName={linkActiveClasses}
                      href="/feed/resources"
                      action="push"
                      exact
                      aria-label={t('menu.resources')}
                    >
                      <ResourcesIcon className={'mr-10'} />
                      <span className="title">{t('menu.resources')}</span>
                    </NavLink>
                  </li>
                ) : null}

                <li className="item-latest">
                  <NavLink
                    className={linkClasses}
                    activeClassName={linkActiveClasses}
                    href="/feed/latest"
                    action="push"
                    exact
                    aria-label={t('landing.latest')}
                  >
                    <LatestIcon className={'mr-10'} />
                    <span className="title">{t('landing.latest')}</span>
                  </NavLink>
                </li>
                <li className="item-trending">
                  <NavLink
                    className={linkClasses}
                    activeClassName={linkActiveClasses}
                    href="/feed/trending"
                    action="push"
                    exact
                    aria-label={t('landing.trending')}
                  >
                    <TrendingIcon className={'mr-10'} />
                    <span className="title">{t('landing.trending')}</span>
                  </NavLink>
                </li>
                <li className="item-featured">
                  <NavLink
                    className={linkClasses + ' pt-hover-secondary-fill'}
                    activeClassName={linkActiveClasses}
                    href="/feed/featured"
                    action="push"
                    exact
                    aria-label={t('content.featured')}
                  >
                    <StarFeaturedIcon className={'mr-10'} />
                    <span className="title">{t('content.featured')}</span>
                  </NavLink>
                </li>
                {forYouFeedEnabled ? (
                  <li className="item-for-you">
                    <NavLink
                      className={linkClasses}
                      activeClassName={linkActiveClasses}
                      href="/feed/for_you"
                      action="push"
                      exact
                      aria-label={t('landing.for_you')}
                    >
                      <PersonIcon className={'mr-10'} />
                      <span className="title">{t('landing.for_you')}</span>
                    </NavLink>
                  </li>
                ) : null}
                <li className="item-bookmarks">
                  <NavLink
                    className={linkClasses}
                    activeClassName={linkActiveClasses}
                    href="/account/bookmarks"
                    action="push"
                    exact
                    aria-label={t('screens.bookmarks.title')}
                  >
                    <BookmarkIcon className={'mr-10'} />
                    <span className="title">
                      {t('screens.bookmarks.title')}
                    </span>
                  </NavLink>
                </li>
              </ul>

              <hr className="nav-pane__nav-group-divider" />

              <ul className="nav-pane__nav-group">
                {switchCommunitiesEnabled && hasMultipleCommunities() && (
                  <li className="item-find-community">
                    <Button
                      className={linkClasses}
                      onClick={handleSwitchCommClick}
                      aria-label={t('navigation.switch_communities')}
                    >
                      <SwitchIcon className={'mr-10'} />
                      <span className="title">
                        {t('navigation.switch_communities')}
                      </span>
                    </Button>
                  </li>
                )}
                <li className="item-invite">
                  <Button
                    className={linkClasses}
                    onClick={handleInviteClick}
                    aria-label={t('sidebar.invite_cta')}
                  >
                    <InviteIcon className={'mr-10'} />
                    <span className="title">{t('sidebar.invite_cta')}</span>
                  </Button>
                </li>
                <li className="item-privacy">
                  <NavLink
                    className={linkClasses}
                    activeClassName={linkActiveClasses}
                    href="/policies/privacy"
                    action="push"
                    exact
                    aria-label={t('common.privacy')}
                  >
                    <Shield className={'mr-10'} />
                    <span className="title">{t('common.privacy')}</span>
                  </NavLink>
                </li>
                <li className="item-terms">
                  <NavLink
                    className={linkClasses}
                    activeClassName={linkActiveClasses}
                    href="/policies/terms"
                    action="push"
                    exact
                    aria-label={t('common.terms')}
                  >
                    <Icon
                      aria-hidden="true"
                      className={'mr-10'}
                      type="error_outline"
                    />
                    <span className="title">{t('common.terms')}</span>
                  </NavLink>
                </li>
              </ul>

              <hr className="nav-pane__nav-group-divider divider-signout" />

              <ul className="nav-pane__nav-group group-signout">
                <li>
                  <Button
                    onClick={() => signOut()}
                    aria-label={t('common.sign_out')}
                  >
                    <Icon
                      aria-hidden="true"
                      className={'mr-10'}
                      type="exit_to_app"
                    />
                    <span className="title">{t('common.sign_out')}</span>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  resourcesEnabled: programSelectors.getProgramResourcesEnabled(state),
  advocateEntitlements: advocateSelectors.getAdvocateEntitlements(state),
  switchCommunitiesEnabled: programSelectors.getSwitchCommunitiesEnabled(state),
  forYouFeedEnabled: programSelectors.getForYouFeedEnabled(state),
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
  canSubmitContent: programSelectors.getProgramCanSubmitContent(state),
  membershipIsActive:
    programMembershipSelectors.getProgramMembershipIsActive(state),
  isMinimized: uiSelectors.getMinimizeHeader(state),
});

const mapDispatchToProps = {
  displayOverlay: uiOperations.displayOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteHeaderNav);
