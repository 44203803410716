import { useCallback, useContext, useEffect, useState } from 'react';
import { LocationDescriptorObject } from 'history';
import { RouteComponentProps } from 'react-router';
import ReactFocusLock from 'react-focus-lock';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  TabsRootContainer,
  TabsList,
} from '@socialchorus/shared-ui-components';
import { Curtain } from '../../components/ui';
import { usePatronSelector } from '../../common/use-patron-selector';
import { uiSelectors } from '../../models/ui';
import { setPageScroll } from '../../patron-routes';
import { NotificationFeed } from './feed/notification-feed';
import { Toolbar } from './toolbar';
import { useRecoilValue } from 'recoil';
import { notificationCountsState } from '../../models/notifications/recoil-state';
import styles from './notification-center.module.scss';
import {
  NotificationCenterTabs,
  NotificationFilterTags,
  SEARCH_RESULT_COUNT_TAG,
} from '../../models/notifications/types';
import { NotificationContext } from './context';
import { CountTag } from './count-tag';
import { MobileOverlayController } from './mobileOverlays/controller';

const ANIMATION_DURATION = 200; //needs to match $animation-duration in notification-center.scss

export type NotificationCenterProps = RouteComponentProps & {
  goBack: LocationDescriptorObject | null; //used to give us a location to go back to when the notification center is closed (might not be able to rely on history.goBack() if its the first route)
};

const mapTabToLabel = (tab: NotificationCenterTabs) => {
  return {
    [NotificationCenterTabs.MESSAGES]: 'notification_center.messages',
    [NotificationCenterTabs.ACTIVITY]: 'notification_center.activity',
    [NotificationCenterTabs.ARCHIVE]: 'notification_center.archive',
  }[tab];
};

export const NotificationCenterRoot: React.FC<NotificationCenterProps> = ({
  history,
  goBack,
}) => {
  const { t } = useTranslation();

  const {
    tabs: {
      activeTab: [activeTab, setActiveTab],
      currentTabs: [tabs],
    },
    multiSelect: {
      enabled: [multiSelectEnabled],
    },
    search: {
      query: [searchQuery],
      tabResultCounts: [tabSearchResultCounts],
    },
    tasksAlertFilterActive: [tasksAlertFilterActive],
  } = useContext(NotificationContext);

  const counts = useRecoilValue(notificationCountsState);
  const [body, setBody] = useState<HTMLDivElement | null>();
  const [closing, setClosing] = useState(false);

  const searchActive = !!searchQuery;

  const appGlobalOverlayIsActive = usePatronSelector((state) =>
    uiSelectors.getOverlayIsActive(state)
  );

  const close = useCallback(() => {
    goBack?.pathname ? history.replace(goBack?.pathname) : history.goBack();
  }, [history, goBack?.pathname]);

  const handleCloseClick = useCallback(() => {
    setClosing(true);

    //wait for the animation to finish
    setTimeout(() => {
      close();
    }, ANIMATION_DURATION);
  }, [close]);

  useEffect(() => {
    if (closing) {
      //trigger the replacement of the global scroll bar before the close animation finishes to avoid a flicker on completion.
      setPageScroll(true);
    }
  }, [closing]);

  const onTabChange = useCallback(
    (tab: string) => {
      setActiveTab(tab as NotificationCenterTabs);
    },
    [setActiveTab]
  );

  return (
    <>
      <Curtain
        onClick={handleCloseClick}
        className="notification-center"
        enabled={!closing}
      />
      <ReactFocusLock disabled={!!appGlobalOverlayIsActive}>
        <TabsRootContainer value={activeTab} onValueChange={onTabChange}>
          <div
            ref={(el) => setBody(el)}
            className={cx(styles.notificationCenter, closing && styles.closing)}
          >
            <div>
              <div className={styles.header}>
                <div
                  className={cx(
                    'font-semibold text-tertiary-heading',
                    styles.title
                  )}
                >
                  {t('notification_center.title')}
                </div>
                <IconButton iconName="close" onClick={handleCloseClick} />
              </div>
              <hr className="bg-black-10" />
              <MobileOverlayController />
              <Toolbar body={body} />
              <TabsList
                className={styles.tabs}
                width="full"
                supportInteractiveTabs
                tabs={tabs.map((tab) => {
                  return {
                    label: (
                      <div
                        className={cx(
                          styles.labelContainer,
                          tab === NotificationCenterTabs.MESSAGES &&
                            styles.messages
                        )}
                      >
                        <span>{t(mapTabToLabel(tab))}</span>
                        {!searchActive && !multiSelectEnabled && (
                          <span className={cx(styles.countTagsContainer)}>
                            {tab === NotificationCenterTabs.MESSAGES && (
                              <CountTag
                                count={counts[tab]?.action_required}
                                tab={tab}
                                type={NotificationFilterTags.ACTION_REQUIRED}
                                container={body}
                              />
                            )}
                            {tab === NotificationCenterTabs.MESSAGES && (
                              <CountTag
                                count={counts[tab]?.important}
                                tab={tab}
                                type={NotificationFilterTags.IMPORTANT}
                                container={body}
                              />
                            )}
                            {!tasksAlertFilterActive && (
                              <CountTag
                                count={counts[tab]?.unread}
                                tab={tab}
                                type={NotificationFilterTags.UNREAD}
                                container={body}
                              />
                            )}
                          </span>
                        )}
                        {searchActive && (
                          <CountTag
                            count={tabSearchResultCounts[tab]}
                            tab={tab}
                            type={SEARCH_RESULT_COUNT_TAG}
                            container={body}
                          />
                        )}
                      </div>
                    ),
                    value: tab,
                  };
                })}
              />
            </div>
            <div className={styles.feedContainer}>
              <NotificationFeed />
            </div>
          </div>
        </TabsRootContainer>
      </ReactFocusLock>
    </>
  );
};
