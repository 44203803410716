import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProgram } from '../../common/use-program';
import useProgramTheme from '../../common/use-program-theme';

interface AssistantAvatarProps {
  className?: string;
  size?: number;
}

export const AssistantAvatar: React.FC<AssistantAvatarProps> = ({
  className,
  size = 40,
}) => {
  const { t } = useTranslation();
  const { themeColor } = useProgramTheme();
  const {
    assistant_mascot_image_url,
    theme: { icon_image_url },
  } = useProgram();
  const imageUrl = assistant_mascot_image_url || icon_image_url;

  return imageUrl ? (
    <img
      src={imageUrl as string}
      alt={t('assistant.assistant')}
      className={className}
      width={size}
      height={size}
    />
  ) : (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} fill={themeColor} />
      <path
        d="M17.8336 8.80778C18.1787 8.92986 18.2796 9.04774 18.6415 9.77182C19.5799 11.6241 20.2363 12.5755 21.3304 13.6701C22.4245 14.7646 23.3755 15.4213 25.227 16.3601C25.7488 16.6211 25.9339 16.739 26.0265 16.8569C26.2495 17.1516 26.3084 17.501 26.1906 17.8336C26.0686 18.183 25.955 18.2756 25.1849 18.6629C23.4723 19.5301 22.4329 20.2373 21.4188 21.2224C20.3458 22.2707 19.5757 23.3778 18.6626 25.188C18.2754 25.9584 18.1829 26.0721 17.8336 26.1942C17.4759 26.3205 17.0509 26.2279 16.79 25.9627C16.6975 25.87 16.4997 25.5375 16.2935 25.1165C15.4309 23.3947 14.7618 22.4222 13.693 21.3487C12.6241 20.2752 11.5722 19.5427 9.86372 18.6924C9.51867 18.5198 9.17362 18.3303 9.10208 18.2756C8.79911 18.0525 8.66025 17.4968 8.81173 17.1347C8.9506 16.8064 9.0558 16.7222 9.83006 16.3265C11.5511 15.4508 12.54 14.7857 13.5162 13.8427C14.5935 12.8029 15.4098 11.6452 16.264 9.936C16.4955 9.48135 16.6975 9.13193 16.79 9.03511C17.0509 8.7741 17.4717 8.67728 17.8336 8.80778Z"
        fill="white"
      />
    </svg>
  );
};
